import * as React from "react"
import GradientJumbo from "../components/gradient-jumbo"
import Layout from "../components/layout"
import BlogListPT from "../components/sections/blog/blog_list_pt"
import Contact from "../components/sections/contact"
import Seo from "../components/seo"

export default function Blog(props) {
  return (
    <Layout language="pt" pathname={props.location.pathname}>
      <Seo
        title="Blog"
        lang="pt"
        description="Fique por dentro das novidades da CAMARADES Brasil"
      />
      <GradientJumbo
        isBlog={true}
        subtitle="Blog"
        title="Novidades CAMARADES Brasil"
      />
      <div id="plus"></div>
      <BlogListPT />
      <Contact language="pt" />
    </Layout>
  )
}
