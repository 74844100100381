import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"

function BlogListPT() {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/(markdown/posts/pt)/" } }
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          edges {
            node {
              id
              frontmatter {
                date(formatString: "DD [de]  MMMM [de] YYYY", locale: "pt")
                title
                cover
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  )

  const [posts, setPosts] = useState([])
  const [end, setEnd] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [index, setIndex] = useState(0)

  useEffect(() => {
    function renderPosts() {
      setPosts(allMarkdownRemark.edges.slice(0, 9))
      setIndex(1)

      if (allMarkdownRemark.edges.length < 9) {
        setEnd(true)
      }
      setLoaded(true)
    }
    renderPosts()
  }, [])

  function renderMore() {
    if (index * 9 >= allMarkdownRemark.edges.length) {
      return setEnd(true)
    }

    if (posts.length < allMarkdownRemark.edges.length) {
      let start = index * 9
      let end = index * 9 * 2

      setPosts([...posts, ...allMarkdownRemark.edges.slice(start, end)])
      setIndex(index + 1)

      if (end > allMarkdownRemark.edges.length) {
        return setEnd(true)
      }
    }
  }

  if (!loaded) {
    return (
      <div
        className="spinner-border text-info container d-flex justify-content-center"
        role="status"
      >
        <span className="sr-only"></span>
      </div>
    )
  }

  return (
    <section className="container">
      <div className="row">
        {posts.length ? posts.map((a, i) => {
          return (
            <Link
              to={a.node.fields.slug}
              className="text-decoration-none d-block col-sm-12 col-md-6 col-lg-4 my-3"
              key={i}
            >
              <div className="post-card">
                <div className="card border-0 post-card-radius">
                  <img
                    src={a.node.frontmatter.cover}
                    className="card-img-top post-card-image-radius"
                    alt={a.node.frontmatter.title}
                  />

                  <div className="card-body">
                    <h4 className="text-center card-title">
                      {a.node.frontmatter.title}
                    </h4>
                    <p className="text-center card-text">
                      {a.node.frontmatter.date}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          )
        })
          :
          <h5 className="alert alert-warning">
            Não há posts ainda
          </h5>
        }
        {!end ? (
          <div
            className="d-flex justify-content-center"
            onClick={renderMore}
            onKeyPress={renderMore}
            role="button"
            tabIndex="0"
          >
            <StaticImage src="../../../assets/images/icons/plus.png" alt="+" />
          </div>
        ) : null}
      </div>
    </section>
  )
}

export default BlogListPT
