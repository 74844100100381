import React from "react"
import "./styles.css"
import PropTypes from "prop-types"

const SmallCard = ({ children }) => {
  return (
    <div className="single-card d-flex flex-column align-items-center justify-content-center p-3">
      {children}
    </div>
  )
}

SmallCard.propTypes = {
  children: PropTypes.node.isRequired,
}

SmallCard.defaultProps = {}

export default SmallCard
