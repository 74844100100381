import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import PrimaryButton from "../buttons/primary_button"
import SmallCard from "../cards/small_card"
import PropTypes from "prop-types"

const texts = {
  pt: [
    "Nós podemos te ajudar",
    "Quer tirar alguma dúvida?",
    "Acompanhe a gente",
    "Receba no Telegram as novidades da CAMARADES Brasil",
    "Entrar no grupo do Telegram",
    "Você pode nos encontrar nas redes sociais também",
  ],
  en: [
    "We can help you",
    "Do you want to ask us a question?",
    "Follow us",
    "Get updated with our Telegram news",
    "Enter the Telegram group",
    "You can find us at social networks too",
  ],
}

function Contact({ language }) {
  const text = texts[language]

  return (
    <section id="contact" className="contact bg-white">
      <div className="container">
        <div className="row">
          <div className="col-md-5 mb-5">
            <h1 className="text-secondary h2 fw-normal">{text[0]}</h1>
            <p className="text-secondary">{text[1]}</p>
            <div className="row py-2 d-flex align-items-center">
              <div className="col">
                <a
                  className="text-decoration-none"
                  href="https://join.slack.com/t/camaradesbr/shared_invite/zt-12f82wifb-4F6Nx6rs2NLi2XuJzetPpw"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SmallCard>
                    <StaticImage
                      src="../../assets/images/icons/slack.png"
                      alt="slack-icon"
                    />
                    <span className="text-center">Slack</span>
                  </SmallCard>
                </a>
              </div>
              <div className="col">
                <a
                  className="text-decoration-none"
                  href="mailto:camaradesbr@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SmallCard>
                    <StaticImage
                      src="../../assets/images/icons/email.png"
                      alt="email-icon"
                    />
                    <span className="text-center">E-mail</span>
                  </SmallCard>
                </a>
              </div>
            </div>
          </div>

          <div className="col-2"></div>

          <div className="col-md-5">
            <h1 className="text-secondary h2 fw-normal">{text[2]}</h1>
            <p className="text-secondary">{text[3]}</p>
            <div className="mt-4 mt-lg-0 d-flex align-items-stretch">
              <a
                href="https://t.me/camaradesbr"
                className="text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PrimaryButton>{text[4]}</PrimaryButton>
              </a>
            </div>
            <p className="text-secondary pt-5 mb-1">{text[5]}</p>
            <div className="row pb-4 pt-2">
              <div className="col-2">
                <a
                  href="https://twitter.com/CAMARADESBR"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StaticImage
                    src="../../assets/images/icons/twitter.png"
                    alt="twitter-icon"
                  />
                </a>
              </div>
              <div className="col-2">
                <a
                  href="https://www.instagram.com/camaradesbr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StaticImage
                    src="../../assets/images/icons/instagram.png"
                    alt="instagram-icon"
                  />
                </a>
              </div>
              <div className="col-2">
                <a
                  href="https://www.youtube.com/channel/UCa3iw7wi4Occ5HaFRwhNdww"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StaticImage
                    src="../../assets/images/icons/youtube.png"
                    alt="youtube-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

Contact.propTypes = {
  language: PropTypes.oneOf(["pt", "en"]),
}

Contact.defaultProps = {
  language: "pt",
}

export default Contact
