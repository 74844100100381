import PropTypes from "prop-types"
import React from "react"
import "./styles.css"

const GradientJumbo = ({ title, subtitle }) => {
  return (
    <section id="gradient-jumbotron" className="d-flex align-items-start">
      <div className="container">
        <div className="row">
          <span className="h5 blue-1 col-12">{subtitle}</span>
          <h1 className="h1 text-start pt-1 fw-normal col-12">{title}</h1>
        </div>
      </div>
    </section>
  )
}

GradientJumbo.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}

GradientJumbo.defaultProps = {
  title: "T",
  subtitle: "S",
}

export default GradientJumbo
